import core from '@/admin/core';

// カテゴリー
export interface CategoryProps {
  name: string;
  color: string;
}

export interface Category extends CategoryProps {
  scheduleCategoryId: string;
}

// スケジュール
interface Schedule {
  scheduleEventId: string;
  eventName: string;
  releaseDate: number;
  scheduleCategoryId: string;
  isAllDay: boolean;
  isRepeat: boolean;
  startDate: number;
  endDate: number;
  eventDetail: string;
  location: string;
  eventStatus: 'draft' | 'confirm';
}

export interface ScheduleItemDraftProps {
  eventName?: string;
  releaseDate?: number;
  scheduleCategoryId?: string;
  isAllDay?: boolean;
  startDate?: number;
  endDate?: number;
  isRepeat?: boolean;
  repeatDay?: number[];
  repeatWeek?: number;
  repeatEndDate?: number;
  eventDetail?: string;
  image?: string;
  location?: string;
  locationURL?: string;
  linkUrl?: string;
  linkText?: string;
  isExternalLink?: string;
  eventStatus: 'draft';
}

export interface ScheduleItemProps {
  eventName: string;
  releaseDate: number;
  scheduleCategoryId?: string;
  isAllDay: boolean;
  startDate: number;
  endDate: number;
  isRepeat: boolean;
  repeatDay?: number[];
  repeatWeek?: number;
  repeatEndDate?: number;
  eventDetail?: string;
  image?: string;
  location?: string;
  locationURL?: string;
  linkUrl?: string;
  linkText?: string;
  isExternalLink?: string;
  eventStatus: 'confirm';
}

export interface ScheduleItem extends ScheduleItemProps {
  scheduleEventId: string;
}

export interface ScheduleSetting {
  scheduleName: string;
  scheduleFormat: 'calendar' | 'list' | string;
  isFormatSwitch: boolean;
}

export interface ScheduleSettingItem {
  settingId: 'schedule';
  value: ScheduleSetting;
}

export const getCategories = async () => {
  try {
    const result = await core.httpClient.get('/admin/public/schedule/categories');
    return result.data as Category[];
  } catch (e) {
    return [] as Category[];
  }
};

export const createCategory = async (props: CategoryProps) => {
  await core.httpClient.post('/admin/public/schedule/categories', props);
};

export const saveCategory = async (scheduleCategoryId: string, props: CategoryProps) => {
  await core.httpClient.put(`/admin/public/schedule/categories/${scheduleCategoryId}`, props);
};

export const deleteCategory = async (scheduleCategoryId: string) => {
  await core.httpClient.delete(`/admin/public/schedule/categories/${scheduleCategoryId}`);
};

export const getSchedules = async (start: number, end: number) => {
  try {
    const result = await core.httpClient.get(`/admin/public/schedule/events?start=${start}&end=${end}`);
    return result.data as Schedule[];
  } catch (e) {
    return [] as Schedule[];
  }
};

export const getSchedule = async (scheduleEventId: string) => {
  try {
    const result = await core.httpClient.get(`/admin/public/schedule/events/${scheduleEventId}`);
    return result.data as ScheduleItem;
  } catch (e) {
    return null;
  }
};

export const createSchedule = async (props: ScheduleItemProps | ScheduleItemDraftProps) => {
  await core.httpClient.post('/admin/public/schedule/events', props);
};

export const saveSchedule = async (props: ScheduleItemProps | ScheduleItemDraftProps, scheduleEventId: string) => {
  await core.httpClient.put(`/admin/public/schedule/events/${scheduleEventId}`, props);
};

export const copySchedule = async (scheduleEventId: string) => {
  try {
    const result = await core.httpClient.post(`/admin/public/schedule/events/${scheduleEventId}`);
    return result.data as ScheduleItem;
  } catch (e) {
    return {} as ScheduleItem;
  }
};

export const deleteSchedule = async (scheduleEventId: string) => {
  await core.httpClient.delete(`/admin/public/schedule/events/${scheduleEventId}`);
};

export const getScheduleSetting = async () => {
  try {
    const result = await core.httpClient.get('/admin/public/settings/schedule');
    return result.data as ScheduleSettingItem;
  } catch (e) {
    return {} as ScheduleSettingItem;
  }
};

export const createScheduleSetting = async (props: ScheduleSettingItem) => {
  await core.httpClient.post('/admin/public/settings', props);
};

export const saveScheduleSetting = async (props: ScheduleSetting) => {
  await core.httpClient.put('/admin/public/settings/schedule', props);
};
