import {
  getCategories,
  createCategory,
  saveCategory,
  deleteCategory,
  getSchedules,
  getSchedule,
  createSchedule,
  saveSchedule,
  deleteSchedule,
  copySchedule,
  getScheduleSetting,
  createScheduleSetting,
  saveScheduleSetting,
  ScheduleItem,
  Category,
  CategoryProps,
  ScheduleItemProps,
  ScheduleItemDraftProps,
  ScheduleSettingItem,
  ScheduleSetting,
} from '@/admin/schedule';
import { DisplayDate } from '@/admin/util';
import { Ref, ref } from '@vue/composition-api';
import { EventSourceApi } from '@fullcalendar/core';

interface FullCalendarProperty {
  source?: EventSourceApi | null;
  start: Date | null;
  end?: Date | null;
  startStr?: string;
  endStr?: string;
  id?: string;
  groupId?: string;
  allDay?: boolean;
  title: string;
  url: string;
  display?: string;
  startEditable?: boolean;
  durationEditable?: boolean;
  constraint?: any;
  overlap?: boolean;
  allow?: any;
  backgroundColor: string;
  borderColor: string;
  textColor?: string;
  classNames?: string[];
  isDraft: boolean; // リリース済み
}

interface ScheduleItemProperty {
  eventName: string;
  scheduleCategoryId?: string;
  releaseDate: DisplayDate;
  startDate: DisplayDate;
  endDate: DisplayDate;
  isAllDay: boolean;
  isRepeat: boolean;
  repeatDay?: number[];
  repeatWeek?: number;
  repeatEndDate?: DisplayDate;
  eventDetail?: string;
  image?: string;
  location?: string;
  locationURL?: string;
  linkUrl?: string;
  linkText?: string;
  isExternalLink?: string;
  eventStatus: 'draft' | 'confirm' | string;
}

class Categories {
  private _items: Ref<Category[]> = ref([]);
  private _item: Ref<Category> = ref({
    scheduleCategoryId: '',
    name: '',
    color: '',
  });
  private _isLoading = ref(false);

  get items() {
    return this._items.value;
  }
  get item() {
    return this._item.value;
  }
  set item(item: Category) {
    this._item.value = item;
  }
  get isLoading() {
    return this._isLoading.value;
  }

  constructor() {
    this.getCategories();
  }

  getCategories = async () => {
    this._isLoading.value = true;
    this._items.value = await getCategories();
    this._isLoading.value = false;
  };

  createProps = () => {
    if (!this.item.name || !this.item.color) {
      throw '必須項目を全て入力してください';
    } else {
      const props: CategoryProps = {
        name: this.item.name,
        color: this.item.color,
      };
      return props;
    }
  };

  deleteCategory = async (categoryId: string) => {
    await deleteCategory(categoryId);
  };

  saveCategory = async () => {
    const props = this.createProps();
    await saveCategory(this.item.scheduleCategoryId, props);
  };

  createCategory = async () => {
    const props = this.createProps();
    await createCategory(props);
  };
}

class Schedule {
  private _scheduleEventId = ref('');
  private _isLoading = ref(false);
  private _categories: Ref<
    {
      value: string;
      title: string;
    }[]
  > = ref([]);
  private _item: Ref<ScheduleItemProperty> = ref({
    eventName: '',
    scheduleCategoryId: '',
    releaseDate: new DisplayDate(),
    isAllDay: false,
    isRepeat: false,
    startDate: new DisplayDate(),
    endDate: new DisplayDate(),
    repeatDay: [],
    repeatWeek: 0,
    repeatEndDate: new DisplayDate(),
    eventDetail: '',
    image: '',
    location: '',
    locationURL: '',
    linkUrl: '',
    linkText: '',
    isExternalLink: '',
    eventStatus: 'draft',
  });
  private _weekDay = ref(['日', '月', '火', '水', '木', '金', '土']);
  private _eventStatus = ref([
    {
      value: 'draft',
      text: '下書き',
    },
    {
      value: 'confirm',
      text: '公開',
    },
  ]);

  get scheduleEventId() {
    return this._scheduleEventId.value;
  }
  get isLoading() {
    return this._isLoading.value;
  }
  get categories() {
    return this._categories.value;
  }
  get item() {
    return this._item.value;
  }
  get weekDay() {
    return this._weekDay.value;
  }
  get eventStatus() {
    return this._eventStatus.value;
  }

  constructor(scheduleEventId?: string) {
    if (scheduleEventId) this._scheduleEventId.value = scheduleEventId;
    this.init();
  }

  init = async () => {
    this._isLoading.value = true;
    const [_item, _categories] = await Promise.all([this.getSchedule(), this.getCategories()]);
    this._item.value = this.formatSchedule(_item);
    this._categories.value = _categories;
    this._isLoading.value = false;
  };

  getCategories = async () => {
    const categories = await getCategories();
    return categories.map((category) => {
      return {
        title: category.name,
        value: category.scheduleCategoryId,
      };
    });
  };

  getSchedule = async (): Promise<ScheduleItem> => {
    if (!this._scheduleEventId.value) return {} as ScheduleItem;
    const _item = await getSchedule(this._scheduleEventId.value);
    if (_item === null) {
      alert('データが取得できませんでした。一覧ページに戻ります。');
      location.href = '/#/schedules';
      return {} as ScheduleItem;
    }
    return _item;
  };

  formatSchedule = (item: ScheduleItem) => {
    const _item: ScheduleItemProperty = {
      eventName: item.eventName,
      releaseDate: new DisplayDate(item.releaseDate),
      isRepeat: !!item.isRepeat,
      startDate: new DisplayDate(item.startDate),
      endDate: new DisplayDate(item.endDate),
      isAllDay: !!item.isAllDay,
      eventStatus: item.eventStatus,
      repeatDay: item.repeatDay || [],
    };
    if (item.scheduleCategoryId) _item.scheduleCategoryId = item.scheduleCategoryId;
    if (item.repeatWeek) _item.repeatWeek = item.repeatWeek;
    if (item.repeatEndDate) _item.repeatEndDate = new DisplayDate(item.repeatEndDate);
    if (item.eventDetail) _item.eventDetail = item.eventDetail;
    if (item.image) _item.image = item.image;
    if (item.location) _item.location = item.location;
    if (item.locationURL) _item.locationURL = item.locationURL;
    if (item.linkUrl) _item.linkUrl = item.linkUrl;
    if (item.linkText) _item.linkText = item.linkText;
    if (item.isExternalLink) _item.isExternalLink = item.isExternalLink;
    return _item;
  };

  createProps = () => {
    if (
      !this.item.eventName ||
      !this.item.releaseDate.value ||
      !this.item.startDate.value ||
      !this.item.endDate.value ||
      !this.item.eventStatus
    ) {
      throw '必須項目を全て入力してください';
    } else if (this.item.startDate.value < this.item.releaseDate.value) {
      throw '公開日時はイベント日時以前に設定してください';
    } else if (
      (!this.item.isAllDay && this.item.endDate.value <= this.item.startDate.value) ||
      (this.item.isAllDay && this.item.endDate.value < this.item.startDate.value)
    ) {
      throw 'イベントの終了日時は開始日時より後に設定してください';
    } else if (this.item.repeatEndDate?.value && this.item.repeatEndDate.value <= this.item.endDate.value) {
      throw '繰り返し設定の終了日はイベント日時より後に設定してください';
    } else if (this.item.isRepeat && this.item.endDate.value - this.item.startDate.value >= 6 * 24 * 60 * 60 * 1000) {
      throw '繰り返しの場合はイベント日時を6日以内で設定してください';
    } else if (this.item.isRepeat && (!this.item.repeatDay?.length || !this.item.repeatWeek)) {
      throw '繰り返し設定の曜日・繰り返す間隔に未入力があります';
    } else if (this.item.eventStatus === 'draft') {
      const props: ScheduleItemDraftProps = {
        eventName: this.item.eventName,
        releaseDate: this.item.releaseDate.value,
        isAllDay: !!this.item.isAllDay,
        startDate: this.item.startDate.value,
        endDate: this.item.endDate.value,
        isRepeat: !!this.item.isRepeat,
        eventStatus: 'draft',
      };
      if (this.item.scheduleCategoryId) props.scheduleCategoryId = this.item.scheduleCategoryId;
      if (this.item.isRepeat) {
        props.repeatDay = this.item.repeatDay;
        props.repeatWeek = this.item.repeatWeek;
        props.repeatEndDate = this.item.repeatEndDate?.value;
      }
      if (this.item.eventDetail) props.eventDetail = this.item.eventDetail;
      if (this.item.image) props.image = this.item.image;
      if (this.item.location) props.location = this.item.location;
      if (this.item.locationURL) props.locationURL = this.item.locationURL;
      if (this.item.linkUrl) props.linkUrl = this.item.linkUrl;
      if (this.item.linkText) props.linkText = this.item.linkText;
      if (this.item.isExternalLink) props.isExternalLink = this.item.isExternalLink;
      return props;
    } else if (
      (this.item.linkUrl && !this.item.linkUrl.match(/^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/)) ||
      (this.item.locationURL && !this.item.locationURL.match(/^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/))
    ) {
      throw 'URLが不正です。ドメインから正しく指定してください';
    } else if (
      (this.item.linkUrl && !this.item.linkText) ||
      (this.item.linkText && !this.item.linkUrl) ||
      (this.item.isExternalLink && (!this.item.linkUrl || !this.item.linkText))
    ) {
      throw 'ボタンを使用する場合はURLとテキストは必須です。';
    } else {
      const props: ScheduleItemProps = {
        eventName: this.item.eventName,
        releaseDate: this.item.releaseDate.value,
        isAllDay: !!this.item.isAllDay,
        startDate: this.item.startDate.value,
        endDate: this.item.endDate.value,
        isRepeat: !!this.item.isRepeat,
        eventStatus: 'confirm',
      };
      if (this.item.isAllDay) {
        props.endDate = new Date(this.item.endDate.value).setHours(23, 59, 59, 0);
      }
      if (this.item.scheduleCategoryId) props.scheduleCategoryId = this.item.scheduleCategoryId;
      if (this.item.isRepeat) {
        props.repeatDay = this.item.repeatDay;
        props.repeatWeek = this.item.repeatWeek;
        props.repeatEndDate = this.item.repeatEndDate?.value;
      }
      if (this.item.eventDetail) props.eventDetail = this.item.eventDetail;
      if (this.item.image) props.image = this.item.image;
      if (this.item.location) props.location = this.item.location;
      if (this.item.locationURL) props.locationURL = this.item.locationURL;
      if (this.item.linkUrl) props.linkUrl = this.item.linkUrl;
      if (this.item.linkText) props.linkText = this.item.linkText;
      if (this.item.isExternalLink) props.isExternalLink = this.item.isExternalLink;
      return props;
    }
  };

  saveSchedule = async () => {
    const props = this.createProps();
    if (!props) return;
    await saveSchedule(props, this._scheduleEventId.value);
  };

  createSchedule = async () => {
    const props = this.createProps();
    if (!props) return;
    await createSchedule(props);
  };

  deleteSchedule = async () => {
    await deleteSchedule(this._scheduleEventId.value);
  };

  copySchedule = async () => {
    this._isLoading.value = true;
    const _item = await copySchedule(this._scheduleEventId.value);
    this._item.value = this.formatSchedule(_item);
    this._scheduleEventId.value = _item.scheduleEventId;
    this._isLoading.value = false;
  };
}

export const getScheduleItems = async (start: number, end: number): Promise<FullCalendarProperty[]> => {
  const [_items, _categories] = await Promise.all([getSchedules(start, end), getCategories()]);
  const items = _items.map((_item) => {
    const category = _categories.find((category) => category.scheduleCategoryId === _item.scheduleCategoryId);
    const item: FullCalendarProperty = {
      title:
        _item.eventStatus === 'confirm' && Date.now() < _item.releaseDate
          ? `【公開予約】${_item.eventName}`
          : _item.eventName,
      start: new Date(_item.startDate),
      url: `/#/schedule/${_item.scheduleEventId}${
        _item.isRepeat ? `?eventStart=${_item.startDate}&eventEnd=${_item.endDate}` : ''
      }`,
      backgroundColor: 'transparent',
      borderColor: category?.color || '#000',
      textColor: category?.color || '#000',
      isDraft: _item.eventStatus === 'draft' ? true : false,
    };
    if (_item.eventStatus === 'confirm') {
      item.backgroundColor = category?.color || '#000';
      item.textColor = '#fff';
    } else if (_item.eventStatus === 'draft' && !_item.isAllDay) {
      item.classNames = ['custom-draft-icon'];
    }
    if (_item.endDate) item.end = new Date(_item.endDate);
    if (_item.isAllDay) {
      item.allDay = _item.isAllDay;
      // 1日後にしてあげる
      const date = new Date(_item.endDate);
      if (_item.endDate) item.end = new Date(date.setDate(new Date(_item.endDate).getDate() + 1));
    }
    return item;
  });
  return items;
};

export class ScheduleSettings {
  _isLoading = ref(false);
  _setting: Ref<ScheduleSetting> = ref({
    scheduleName: 'スケジュール',
    scheduleFormat: 'calendar',
    isFormatSwitch: true,
  });

  get isLoading() {
    return this._isLoading.value;
  }
  get setting() {
    return this._setting.value;
  }

  constructor() {
    this.getScheduleSetting();
  }

  getScheduleSetting = async () => {
    this._isLoading.value = true;
    const setting = await getScheduleSetting();
    if (!setting) {
      const props = this.creteProps();
      if (!props) return;
      const createSettingProps: ScheduleSettingItem = {
        settingId: 'schedule',
        value: props,
      };
      await createScheduleSetting(createSettingProps);
    } else {
      this._setting.value = setting.value;
    }
    this._isLoading.value = false;
  };

  saveScheduleSetting = async () => {
    const props = this.creteProps();
    if (!props) return;
    await saveScheduleSetting(props);
  };

  creteProps = () => {
    if (!this._setting.value.scheduleName) throw '必須項目を入力してください';
    else {
      return this._setting.value;
    }
  };
}

export const useCategories = () => {
  const categories = new Categories();
  return categories;
};

export const useSchedule = (scheduleEventId?: string) => {
  const schedule = new Schedule(scheduleEventId);
  return schedule;
};

export const useScheduleSetting = () => {
  const setting = new ScheduleSettings();
  return setting;
};
